<template>
  <div class="login">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-8 m-auto">
          <img alt="Whistle logo" class=" mx-auto d-block" src="../assets/logo.png">
          <h1 class="text-center mt-2" >Iniciar Sesión</h1>
          <form v-on:submit.prevent="login" class="mt-4">
            <div class="form-group">
              <input type="username" class="form-control form-control-lg" placeholder="Usuario" name="username" autocomplete="off" required v-model="username">
            </div>
            <div class="form-group">
              <input type="password" class="form-control form-control-lg" placeholder="Contraseña" name="password" v-model="password">
            </div>
            <input type="submit" class="btn btn-info btn-block mt-4" value="Entrar">
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    login() {
      // Convertir el nombre de usuario a minusculas y eliminar todos los espacios
      this.username = this.username.toLowerCase().replace(/\s/g, '');
      // Encriptar contraseña
      var hash = this.$CryptoJS.SHA256(this.password).toString();
      const user = {
        username: this.username,
        password: hash
      }
      this.axios.post('/api/admins/login', user)
        .then(response => {
          const data = response.data.message;
          if (response.data.status === 'error') {
            console.log(data);
            this.$showToast(data, 'error');
          } else {
            localStorage.setItem('token', data.token)
            localStorage.setItem('name', data.name)
            localStorage.setItem('id', data._id)
            localStorage.setItem('username', data.username)
            localStorage.setItem('role', data.role)
            localStorage.setItem('latitude', data.latitude)
            localStorage.setItem('longitude', data.longitude)
            this.$router.replace('/home');
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$router.replace('/home');
    }
  },
}
</script>

<style scoped>
.row {
  height: 100vh;
}
img {
  width: 50px;
}
form {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 60px;
}
</style>